.card {
    width: 100%;
    display: flex;
    color: $text-color;
    flex-direction: column;
    justify-content: center;
    padding: $padding;
    border-radius: $border-radius;
    background-color: $card-background-color;
    margin-bottom: calc(#{$margin});
    max-height: calc(100vh - #{$header-height} - (#{$margin} * 2));

    ::-webkit-scrollbar {
        display: none;
    }

    &:hover {
        ::-webkit-scrollbar {
            display: block;
            animation: $fade-in-animation;
        }
    }

    &.list {
        max-height: calc(100vh - (#{$header-height} * 2) - (#{$margin} * 2) );

        .card-content {
            position: relative;
            width: 100%;
            height: 100%;
            overflow-y: auto;
            overflow-x: auto;
            padding: 0 $card-padding;

            @media #{$large-and-up} {
                margin-top: $card-padding;
            }
        }
    }

    .card-title {
        color: $label-color;
        text-transform: capitalize;
        font-size: $title-font-size;
        font-weight: $font-weight-medium;
        padding: 0 $card-padding 1rem $card-padding;
        // border-bottom: $divider;
        margin-bottom: $margin;
    }

    .card-content {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0 $card-padding;
        overflow-y: auto;
        overflow-x: hidden;
        animation: $fade-in-animation;
    }

    .card-filter {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 $card-padding;
        width: 100%;

        .left-section {
            input {
                margin: $no-margin;
            }
        }
    }

    @media screen and (max-width: $medium-screen) {
        &.list {
            margin: $no-margin;
            padding: $no-padding;
        }

        .card-filter {
            width: 100vw;
            display: inline-flex;
            flex-direction: column;

            .left-section {
                width: 100%;
                padding: $padding;
            }

            .right-section {
                padding: .5rem 0;
            }
        }
    }
}
