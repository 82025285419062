$info-color: #CCCCCC;
$warn-color: #FFA500;
$error-color: #FF3333;
$link-color: #0066CC;
$dark-color: #000000;
$light-color: #FFFFFF;
$icon-color-one: #BDBDBD;
$icon-color-two: #E0E0E0;
$primary-color: #ED1C24;
$success-color: #00CC66;
$tertiary-color: #CCCCCC;
$secondary-color: #333333;
$background-color: #F0F0F9;
$icon-color: $icon-color-one;
$scrollbar-color: $icon-color-one;
$border-color: $background-color;
$text-color: rgba($dark-color, 60%);
$sidebar-link-color: $text-color;
$card-background-color: $light-color;
$label-color: rgba($dark-color, 38%);
$sidebar-label-color: $label-color;
$disabled-text-color: $label-color;
$disabled-background-color: #D5D5E3;
$sidebar-background-color: $light-color;
$primary-text-color: rgba($dark-color, 87%);