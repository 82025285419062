.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: $sidebar-width;
    height: 100vh;
    z-index: 999;
    transition: $transition;
    scrollbar-width: thin;
    scrollbar-color: $icon-color transparent;
    box-shadow: $box-shadow;
    background-color: $sidebar-background-color;
    animation: $fade-in-animation;


    .toggle-sidebar-btn {
        position: absolute;
        right: 0;
        z-index: 1000;
        margin-right: $margin;
        margin-top: $margin;
        font-size: $icon-font-size;
        color: $primary-color;
        border-radius: 50%;
        cursor: pointer;
        transition: $transition;
        animation: $page-animation;
        box-shadow: $button-box-shadow;
    }

    @media (max-width: $large-screen) {
        .toggle-sidebar-btn {
            display: none;
        }
    }

    .logo-container {
        height: 130px;
        width: $sidebar-width;
        padding-bottom: 10px;

        .logo {
            display: block;
            width: 100%;
            height: 90%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            background-color: inherit;
        }

        div {
            font-size: $title-font-size;
            font-weight: $font-weight-semibold;
            color: $label-color;
            text-align: center;
            text-transform: capitalize !important;
        }
    }

    .sidebar-nav {
        height: 100%;
        list-style: none;
        overflow-y: auto;
        border-top: $divider;
        padding: $padding $padding 150px $padding;

        &::-webkit-scrollbar {
            display: none !important;
        }

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            transition: $transition;

        }

        .nav-item {
            margin-bottom: 10px;
            transition: $transition;
        }

        .nav-link {
            display: flex;
            align-items: center;
            font-size: $font-size;
            font-weight: $font-weight-semibold;
            transition: $transition;
            padding: 15px $padding;
            border-radius: $border-radius;
            text-transform: capitalize;
            color: $text-color;
            background: $sidebar-background-color;
            outline: none;
            border: none;

            i {
                font-size: $icon-font-size;
                margin-right: calc($margin + 10px);
                color: $icon-color;
            }

            &:hover,
            &.active,
            &.active i {
                color: $primary-color;
                background: $background-color;
            }

            &:hover i {
                color: $primary-color;
            }
        }

        .nav-content {
            padding: 10px 0 0 0;
            margin: 0;
            list-style: none;
            display: none;
            background-color: $sidebar-background-color;
            max-height: 0;
            transition: $transition;

            &.show {
                max-height: 100vh;
                display: block;
            }

            a {
                display: flex;
                align-items: center;
                font-size: $caption-font-size;
                font-weight: $font-weight-regular;
                color: $text-color;
                transition: $transition;
                padding: 5px 0 10px 40px;
                text-transform: capitalize;
                transition: $transition;

                &:hover,
                &.active {
                    color: $primary-color;
                }
            }

            i {
                font-size: $icon-font-size;
                margin-right: 10px;
                line-height: 0;
            }
        }

    }

    .version-container {
        display: none;
        // position: inherit;
        // right: 0;
        // left: 0;
        // bottom: 2.5px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        // text-transform: lowercase;
        // font-size: $font-size;
        // font-weight: $font-weight-semibold;
        // color: $label-color;
        // letter-spacing: 2px;
        // cursor: pointer;

        // @media screen and (max-width: $medium-screen) {
        //     bottom: 15px;
        // }
    }
}

@media (max-width: $large-screen) {

    .sidebar {
        left: -$sidebar-width !important;
    }

    .breadcrumb {
        width: 100% !important;
    }

    .floating-button-menu {
        display: block !important;
    }
}

// @media (min-width: $large-screen-up) {

//     #main,
//     #footer {
//         margin-left: $sidebar-width;
//     }
// }

@media (max-width: $large-screen) {

    .toggle-sidebar .sidebar,
    .toggle-sidebar {
        left: 0 !important;
    }
}

@media (min-width: $large-screen-up) {

    .toggle-sidebar #main,
    .toggle-sidebar #footer {
        margin-left: 0;
    }

    .toggle-sidebar .floating-button-menu {
        display: block !important;
    }

    .toggle-sidebar .breadcrumb {
        width: 100% !important;
    }

    .toggle-sidebar .sidebar,
    .toggle-sidebar {
        left: -$sidebar-width !important;
    }
}