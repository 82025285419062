/* Fonts */
$font-family: "Quicksand";
$font-size: 1rem;
$heading-font-size: 1.25rem;
$sub-heading-font-size: 1rem;
$caption-font-size: 0.875rem;
$title-font-size: 1.125rem;
$font-weight-bold: 700;
$font-weight-semibold: 600;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;
$icon-font-size: 1.5rem;
$sm-icon-font-size: 1.25rem;
$space: 1rem;

/* Layout Sizes */
$border-radius: 1rem;
$sidebar-width: 18.75rem;
$header-height: 3.75rem;
$card-padding: 0rem;
$badge-height: 2rem;
$table-header-height: 3.75rem;
$table-data-height: 3.75rem;
$scrollbar-size: 0.5rem;
$box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.1);

/* Animations */
$fade-in-animation: fadeIn 0s ease-in-out;
$transition: background-color ease 0.3s;
$page-animation: fadeIn 0s;
$slide-in-animation: slideIn 0s ease-in-out;
$rotate-animation: rotate;
$pop-animation: pop 0s ease-in-out;
$bell-shake-animation: bellShake 2.5s infinite;

/* Inputs */
$button-border-radius: $border-radius;
$input-margin: 0.5rem;
$input-height: 3.75rem;
$input-padding: 1rem;
$input-border-radius: 0.5rem;
$button-height: $input-height;
$button-padding: $input-padding;
$button-box-shadow: 0 0 0 0.5rem rgba(0, 0, 0, 0.1);
$checkbox-size: 1rem;

$divider: 0.125rem solid $background-color;

/* Margin and Padding */
$margin: 1.5rem;
$padding: $margin;
$no-margin: 0 !important;
$no-padding: $no-margin;

/* Screen Width Above */
$small-screen-up: 37.5rem;
$medium-screen-up: 62rem;
$large-screen-up: 75.0625rem;

/* Screen With Below */
$small-screen: 37.5rem;
$medium-screen: 62rem;
$large-screen: 75.0625rem;

/* Screen Condition */
$medium-and-up: "only screen and (min-width: #{$small-screen-up})";
$large-and-up: "only screen and (min-width: #{$medium-screen-up})";
$extra-large-and-up: "only screen and (min-width: #{$large-screen-up})";
$small-and-down: "only screen and (max-width: #{$small-screen})";
$medium-and-down: "only screen and (max-width: #{$medium-screen})";
$medium-only: "only screen and (min-width: #{$small-screen-up}) and (max-width: #{$medium-screen})";

/* Grid */
$num-cols: 12;
$gutter-width: 1.25rem;
$element-top-margin: 0.416666667rem;
$element-bottom-margin: 0.833333333rem;
