.report {
    margin: 0 !important;
    width: 100%;
    height: 100%;
    color: #000;
    font-size: 15px;

    .report-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .left-section {
            .logo-container {
                max-width: 150px;

                .logo {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .right-section {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            text-transform: capitalize;

            .name {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 1.5px;
            }

            .area {
                font-weight: 500;
                margin: 1.5px 0;
                text-transform: capitalize;
            }

            .tin {
                margin-top: 1rem
            }

            .date {
                font-size: 14px;
            }
        }
    }

    .customer {
        margin-bottom: 1rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .name {
            text-transform: capitalize;
            font-weight: 500;
            margin: 1.5px 0;
            font-size: 15px;
        }

        .area {
            text-transform: capitalize;
            font-weight: 500;
            margin: 1.5px 0;
        }

        .report-type {
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
        }

    }

    .report-body {
        table {
            border: 2px solid $border-color !important;
            width: 100%;
            border-collapse: collapse;

            th {
                text-transform: capitalize;
                font-size: $font-size;
                font-weight: $font-weight-semibold;
                color: $dark-color;
                background-color: $light-color;
                position: sticky;
                top: 0;
                border: 2px solid $border-color !important;
                height: $table-header-height;
                background-color: #fff !important;
            }

            tr {
                cursor: pointer;
                text-transform: capitalize;
                vertical-align: middle;
                border: 2px solid $border-color !important;
                // &:nth-child(even) {
                //     background-color: $primary-color !important;
                // }
            }

            td {
                font-size: 14px;
                font-weight: $font-weight-regular;
                color: $dark-color;
                border: 2px solid $border-color !important;
                height: $table-data-height;
                background-color: #fff !important;
            }

            tfoot {
                display: table-row-group;
            }
        }
    }

    .payment-title {
        font-weight: 600;
        font-size: 15px;
        text-transform: capitalize;
        margin-bottom: 5px;
    }

    .method {
        margin: 3px 0;
        word-spacing: 1.5px;
    }

    .top-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .report-type {
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
        }

        .number {
            margin-bottom: 1rem;
            font-size: 15px;
            text-transform: capitalize;
            font-weight: 500;
        }
    }

}