table {
    width: 100%;
    position: relative;
    border-radius: $border-radius;
    background-color: $card-background-color;

    th,
    td {
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: break-word;
    }

    caption {
        text-align: left;
        text-transform: capitalize;
        font-size: 16px;
        padding: 1rem 0;
        font-weight: bold;
    }

    th {
        text-transform: capitalize;
        font-size: $font-size;
        font-weight: $font-weight-semibold;
        color: $light-color;
        background-color: rgba($primary-color, 1);
        text-align: left;
        position: sticky;
        top: 0;
        height: $table-header-height;
        padding: 0 $padding;

        &.sticky {
            width: 100%;
            position: sticky;
            left: 0;
            z-index: 5;
        }

        &.sticky-right {
            width: 100%;
            position: sticky;
            right: 0;
            z-index: 5;
        }
    }

    tr {
        cursor: pointer;
        // text-transform: capitalize;
        vertical-align: middle;

        // animation: $slide-in-animation;
        &:nth-child(even) {
            background-color: rgba($background-color, .8);
        }

        &:hover {
            background-color: rgba($background-color, .5);
        }

    }

    td {
        font-size: $caption-font-size;
        font-weight: $font-weight-regular;
        text-align: left;
        color: $text-color;
        height: $table-data-height;
        padding: 0 $padding;

        &.sticky {
            position: sticky;
            left: 0;
            z-index: 1;
            background-color: rgba($background-color, 1);
        }

        &.sticky-right {
            position: sticky;
            right: 0;
            z-index: 1;
            background-color: rgba($background-color, 1);
        }

        img {
            height: 100%;
            // width: 100%;
        }
    }

    tfoot {
        border-top: 1px solid $border-color;
    }

    @media screen and (max-width: $medium-screen) {
        thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        tr {
            display: block;
            margin-bottom: $margin;
        }

        td {
            white-space: pre-wrap;
            text-overflow: ellipsis;
            word-wrap: break-word;
            padding: $padding;
            border: 1px solid $border-color;
            border-bottom: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: $caption-font-size;
            text-align: right !important;
            height: auto;

            &:last-child {
                border-bottom: 1px solid $border-color;
            }

            &::before {
                content: attr(data-label);
                float: left !important;
                font-size: $font-size;
                font-weight: $font-weight-semibold;
                color: $label-color;
            }
        }
    }
}