.modal {
    position: fixed;
    z-index: 999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba($background-color, .7);
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;

    &.show {
        visibility: visible;

        .modal-body {
            animation: $slide-in-animation;
        }
    }

    .modal-body {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: $border-radius;
        background-color: $card-background-color;
        padding: $card-padding 0px;
        color: $text-color;
        width: 50%;
        margin-bottom: 10px;
        max-height: calc(100vh / 1.2);

        .modal-title {
            color: $label-color;
            font-size: $title-font-size;
            font-weight: $font-weight-semibold;
            text-transform: capitalize;
            padding: 0 $card-padding 1rem $card-padding;
            border-bottom: $divider;
        }

        .modal-content {
            width: 100%;
            height: 100%;
            padding: 0 $card-padding 0 $card-padding;
            overflow-y: auto;
            overflow-x: hidden;

            form {
                padding-top: $card-padding;
            }

            input,
            textarea,
            label,
            .helper-text,
            a,
            table {
                animation: none;
                transition: none;
            }

        }

        .modal-footer {
            padding: 0 $card-padding;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            button {
                animation: none;
                transition: none;
            }
        }

        @media screen and (max-width: $large-screen) {
            width: 65%;
        }

        @media screen and (max-width: $medium-screen) {
            width: 85%;
        }

        @media screen and (max-width: $small-screen) {
            width: 95%;
        }
    }
}