.preview {
    width: 100%;
    height: 100%;

    .preview-header {
        display: flex;
        flex-direction: column;

        .preview-logo {
            width: 100px;
            height: 100px;

            img {
                width: 100%;
                height: 100%;
                // border-radius: 100%;
                object-fit: contain;
            }
        }

        .preview-sub-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .left-section {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                justify-content: center;

                .name {
                    font-size: $heading-font-size;
                    font-weight: $font-weight-bold;
                }

                .title {
                    font-size: $sub-heading-font-size;
                    font-weight: $font-weight-semibold;
                    color: $label-color;
                }
            }

            .right-section {
                width: 150px;
                height: 150px;

                img {
                    width: 100%;
                    height: 100%;
                    // border-radius: 100%;
                    // object-fit: contain;
                }
            }
        }
    }

    .contacts {
        margin: $margin 0;
        border-radius: $border-radius;
        padding: $padding;
        height: $header-height;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .title {
            text-transform: capitalize;
            font-size: $sub-heading-font-size;
            color: $light-color;
        }

        .right-section {
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            a {
                width: 25px;
                height: 25px;
                margin-left: 10px;
                margin-right: 10px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .vcard {
        margin: 2.5rem 0;
        text-align: right;

        a {
            height: $header-height;
            color: $light-color;
            text-transform: capitalize;
            padding: $padding;
            border-radius: $border-radius;
        }
    }

    .products {
        margin: $margin 0;

        .title {
            text-align: center;
            color: $label-color;
            text-transform: capitalize;
            font-size: $sub-heading-font-size;
        }

        .product {
            margin: $margin 0;
            // border-radius: $border-radius;
            // padding: $padding;

            img {
                width: 100%;
                height: 150px;
                object-fit: cover;
                // border-radius: $border-radius;
            }

            .name {
                // margin-top: 10px;
                font-size: 12px;
                text-transform: capitalize;
                text-align: center;
                color: $light-color;
            }
        }
    }

    .services {
        margin: $margin 0;

        .title {
            text-align: center;
            color: $label-color;
            text-transform: capitalize;
            font-size: $sub-heading-font-size;
        }

        .service {
            width: 100%;
            height: 150px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding: $padding;
            border-radius: $border-radius;
            margin: 1.5rem 0;

            .image-container {
                height: 150px;
                width: 150px;
                padding: $padding;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: $border-radius;
            }

            .name {
                text-transform: capitalize;
                text-align: center;
                color: $light-color;
                margin-left: calc($margin * 1);
            }

        }

    }

    .about {
        margin: 3rem 0;
        width: 100%;

        .title {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $light-color;
            text-align: center;
            height: $header-height;
            text-transform: uppercase;
            border-radius: $border-radius;

        }

    }

    .footer {
        width: 100%;
        margin: 1.5rem 0;
        color: $light-color;
        height: calc($header-height * 2);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: $padding;
        border-radius: $border-radius;

    }
}