.checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    label {
        margin-left: 10px;
        cursor: pointer;
    }

    [type=checkbox] {

        cursor: pointer;
        height: $checkbox-size;
        width: $checkbox-size;
        border-radius: $input-border-radius;
        outline: none;
        border: none;


        &:checked {
            accent-color: $primary-color;
            height: $checkbox-size;
            width: $checkbox-size;
            // animation: $pop-animation;
        }
    }
}