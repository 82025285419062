.dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    transform: translate(-50%, -50%);
    background-color: rgba($background-color, .7);
    z-index: 999;

    &.show {
        visibility: visible;

        .dialog-content {
            animation: $page-animation;
        }
    }

    .dialog-content {
        background-color: $card-background-color;
        padding: 30px 26px;
        display: block;
        border-radius: $border-radius;
        width: calc($sidebar-width + 150px);

        .dialog-title {
            font-size: $title-font-size;
            font-weight: $font-weight-semibold;
            margin-bottom: calc($margin - 10px);
            color: $text-color;
            text-transform: capitalize;
        }

        .dialog-text {
            font-size: $font-size;
            color: $label-color;
        }

        .dialog-body {
            position: relative;
        }

        .dialog-action {
            margin-top: calc($margin - 10px);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            button {
                animation: none;
                transition: none;
            }
        }
    }


}