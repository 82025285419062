.payment-wrapper {
    padding-bottom: 23px;
    animation: $page-animation;

    .payment {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 0 $card-padding;
        transition: $transition;
        animation: $page-animation;
        border: $divider;
        height: 100px;
        width: 100%;
        cursor: pointer;
        &:hover {
            box-shadow: $box-shadow;
        }

        .content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            .icon {
                height: 60px;
                width: 60px;
                // background-color: $primary-color;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 100px;
                transition: $transition;
                animation: $pop-animation;
                margin-right: $margin;

                i {
                    font-size: 2em;
                    color: $light-color;
                }
            }

            .detail {
                .title {
                    text-transform: capitalize;
                    font-weight: $font-weight-regular;
                    font-size: $title-font-size;
                    color: $text-color;
                }

                .amount {
                    color: $label-color;
                    font-weight: $font-weight-semibold;
                    font-size: 1.5em;
                }
            }
        }
    }
}