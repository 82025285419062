.view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: $border-radius;
    background-color: $card-background-color;
    padding: $card-padding 0px;
    color: $text-color;
    width: 100%;
    animation: $page-animation;
    margin-bottom: 10px;
    max-height: calc(100vh - $header-height - $margin);

    .view-profile {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .img {
            width: 130px;
            height: 130px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .view-initials {
            background-color: $primary-color;
            color: $light-color;
            text-transform: uppercase;
            font-size: calc($title-font-size + 10px);
            font-weight: $font-weight-light;
            padding: 2rem;
            border-radius: 100px;
            margin-bottom: 10px;
            width: 100px;
            height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .view-title {
            text-transform: capitalize;
            font-size: calc($font-size + 5px);
            font-weight: $font-weight-semibold;
            color: $label-color;
            display: flex;
            flex-direction: row;
            align-items: center;

            span {
                text-transform: capitalize;
            }

            i {
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }

    .view-items {
        width: 100%;
        height: 100%;
        padding: 0 $card-padding 0 $card-padding;
        margin-top: 10px;
        overflow-y: auto;
        animation: $slide-in-animation;

        .view-item {
            border-radius: $border-radius;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 65px;
            margin: 10px 0;
            padding: 0 $padding;
            border-left: 5px solid $card-background-color;
            transition: $transition;


            &:hover,
            &.active {
                border-left: 5px solid $primary-color;
                background-color: $background-color;
            }

            i {
                margin-right: 10px;
                color: $primary-color;
                font-size: $icon-font-size;
            }

            .title {
                text-transform: capitalize;
                font-size: $font-size;
                font-weight: $font-weight-regular;
                color: $text-color;
            }
        }

        .view-detail {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 65px;
            margin: 10px 0;
            padding: 0 $padding;
            transition: $transition;
            background-color: $background-color;
            border-radius: $border-radius;

            // @media screen and (max-width: $small-screen) {
            //     flex-direction: column;
            //     align-items: flex-start;
            //     justify-content: center;
            // }

            .label {
                margin-right: 10px;
                text-transform: capitalize;
                color: $label-color;
                font-weight: $font-weight-semibold;
                font-size: $font-size;
            }

            .title {
                text-transform: capitalize;
                color: $text-color;
                font-weight: $font-weight-regular;
                font-size: $font-size;
            }
        }
    }

    .view-header {
        display: flex;
        margin: 0;
        padding: 0 $card-padding;
        border-bottom: $divider;
        width: 100%;
        height: $header-height;

        .title {
            font-size: $title-font-size;
            font-weight: $font-weight-semibold;
            text-transform: capitalize;
            color: $primary-color;
        }
    }
}